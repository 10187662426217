import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { IoIosNotifications } from "react-icons/io";
import { IoHome, IoPerson } from "react-icons/io5";
import { RiLogoutCircleLine } from "react-icons/ri";
import { GoUnlink } from "react-icons/go";
import { FcCheckmark } from "react-icons/fc";
import { MdLocationOn } from "react-icons/md";
import { MdPhoneIphone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FiInstagram } from "react-icons/fi";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { HiOutlineMenu } from "react-icons/hi";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";


export const EyeIcon = () => <IoIosEye />;
export const EyeOffIcon = () => <IoIosEyeOff />;
export const IoMdSettingsIcon = () => <IoMdSettings />;
export const IoIosNotificationsIcon = () => <IoIosNotifications />;
export const IoHomeIcon = () => <IoHome />;
export const IoPersonIcon = () => <IoPerson />;
export const RiLogoutCircleLineIcon = () => <RiLogoutCircleLine />;
export const GoUnlinkIcon = () => <GoUnlink />;
export const FcCheckmarkIcon = () => <FcCheckmark />;
export const Location = () => <MdLocationOn />;
export const Phone = () => <MdPhoneIphone />;
export const Email = () => <MdEmail />;
export const Insta = () => <FiInstagram />;
export const Facebook = () => <FaFacebookF />;
export const Twitter = () => <FaXTwitter />;
export const Hamberger = () => <HiOutlineMenu />;
export const Edit = () => <MdModeEditOutline />;
export const IconDelete = () => <RiDeleteBin6Line />;
export const IconRxCross2 = () => <RxCross2 />;